@import '../../App.scss';

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

.portfolio-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 80%;
      float: left;
      clear: none;
    }
  }

  .portfolio-container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: var(--form-color);
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 0 20px;
    box-sizing: border-box;
  }

  input[type='file'] {
    width: 100%;
    border: 0;
    background: var(--form-color);
    height: 50px;
    font-size: 16px;
    color: var(--card-text);
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: var(--form-color);
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: var(--primary-color);
    font-size: 16px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid var(--primary-color);
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
    background-color: var(--form-color);
    transition: 0.2s;
    cursor: pointer;
    }
  }
  .file-button {
    color: var(--primary-color);
    font-size: 16px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid var(--primary-color);
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
    width: 95%;

    &:hover {
    background-color: var(--form-color);
    transition: 0.2s;
    cursor: pointer;
    }
  }
}
