@import '../../App.scss';

.stage-cube-cont {
  width: 50%;
  height: 100%;
  padding-top: 18%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.text-zone {
  max-height: 100vh; // Keep the maximum height to the viewport height
  overflow-y: auto; // Allow vertical scrolling
  padding: 20px 20px 50px 20px; // Adjust padding for top, right, bottom, and left
  margin-top: 40px; // Space at the top
  line-height: 1.6; // Line spacing for readability
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
    padding-bottom: 18%;
    margin-top: 50px; /* Add margin to create space at the top */
  }

  .text-zone {
    max-height: none; // Remove max height restriction on smaller screens
    overflow-y: auto; // Ensure scrolling is enabled
    font-size: 16px; // Adjust font size for smaller screens
    margin-top: 40px; // Top margin for consistency
    padding: 20px 20px 50px 20px; // Adjust padding for smaller screens
  }

  .blank {
    display: block;
    padding-top: 15%;
    margin-top: 50px; /* Add margin to create space at the top */
  }
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left:  calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid var(--primary-color);
    background: rgba(198, 198, 198, 0.15);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 4px 0px var(--secondary-color);
  }

  .face1 {
    transform: translateZ(100px);
  }
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
  }
  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  }
  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  }
  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  }
  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
  }
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateX(0deg) rotateY(-90deg) rotateZ(0deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateZ(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 1200px) {
  .stage-cube-cont {
    position: initial;
    width: 100%;
    height: 0%;
    overflow: visible;
    padding-bottom: 18%
  }
  .blank {
    display: block;
    padding-top: 15%;
  }

  .text-zone {
    font-size: 16px; /* Adjust the font size for smaller screens */
    line-height: 1.6; /* Adjust the line height for better readability */
  }

  .cube-container {
    width: 100%;
    background-color: transparent;
    height: 350px;
  }
}
