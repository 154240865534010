@import '../../App.scss';

.nav-bar {
  background: var(--navbar-color);
  width: 100%;
  height: 120px;
  position: absolute;
  top: 0;
  z-index: 3;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      margin: 16px;
      margin-bottom: 0;
      margin-left: 35px;
      width: 50px;
      height: auto;
    }

    h1 {
      margin: 0;
      color: white;
      font-size: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
      background: var(--navbar-color);
    }
  }



  nav {
    display: flex;
    justify-content: space-around;
    text-align: center;
    position: absolute;
    height: 20px;
    top: 25%;
    margin-top: 0px;
    width: 100%;

    a {
      font-size: 40px;
      color: var(--primary-color);
      display: block;
      line-height: 51px;
      height: 10px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }


      &:hover {
        color: var(--background-color);

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-family: Helvetica;
        font-size: 18px;
        letter-spacing: 2px;
        position: inherit;
        bottom: 45px;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.active {
      svg {
        color: var(--secondary-color);
      }
    }
  }

  ul {
    position: absolute;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
    top: 20px;
    right: 16px;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 20px;
        line-height: 16px;
        color: #4d4d4e;
      }

      .anchor-icon{
        height: 20px;
      }

      &:hover {
        .anchor-icon {
          color: var(--primary-color);
        }
      }
    }
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;
    nav {
      display: none;
    }

    ul {
      top: 50px;
      z-index: 2;
      .anchor-icon {
        font-size: 24px;
      }
    }
    h1 {
      display: none;
    }

    nav {
      width: 100%;
      height: 100%;
      background: white;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 2;
      margin: 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;

        &:hover {
          color: var(--secondary-color);
        }
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg {
        opacity: 1 !important;
      }

      a.active {
        svg {
          color: whitesmoke;
        }
      }

      &.mobile-show {
        display: block;
      }
    }

    .hamburger-icon,
    .close-icon {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
}
