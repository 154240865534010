@import '../../App.scss';

.portfolio-page {
  display: flex; // Add flexbox to center content
  flex-direction: column; // Stack items vertically
  align-items: center; // Center align items
  justify-content: center; // Center items vertically
  height: 100vh; // Full viewport height for centering
  text-align: center; // Center align text

  h1.page-title {
    margin: 0; // Remove margin to adjust positioning
    padding-top: 50px; // Space at the top for "Kostas Portfolio"
    font-size: 2.5rem; // Adjust font size for better appearance
    color: var(--primary-color); // Use primary color for consistency
  }

  .construction-container {
    margin-top: 20px; // Adjust margin for spacing
    padding: 20px; // Add padding for spacing
    border: 1px solid #ddd; // Light border for emphasis
    border-radius: 10px; // Rounded corners for a modern look
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px; // Soft shadow for depth
    background-color: #f8f8f8; // Light background for readability
    max-width: 600px; // Constrain width to look better on larger screens

    .construction-message {
      font-size: 2em; // Larger font size for emphasis
      color: #ff9900; // Use a construction color
      margin-bottom: 20px; // Spacing between message and details
      font-weight: bold; // Bold text for emphasis
    }

    .construction-details {
      font-size: 1.2em; // Smaller font for additional details
      color: #666; // Use a neutral color for details
    }
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-page {
    padding: 20px;
    box-sizing: border-box;

    h1.page-title {
      font-size: 2rem; // Adjust font size for smaller screens
      padding-top: 20px; // Adjust padding for smaller screens
    }

    .construction-container {
      margin-top: 10px; // Adjust margin for smaller screens
      padding: 10px; // Reduced padding for smaller screens
      .construction-message {
        font-size: 1.5em; // Adjust font size for smaller screens
      }
      .construction-details {
        font-size: 1em; // Adjust font size for smaller screens
      }
    }
  }
}
