@import '../../App.scss';

.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
    text-align: left;
  }

  h1 {
    color: var(--primary-color);
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    img{
      width: 60px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 0.7s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: var(--secondary-color);
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    background-color: var(--background-color);
    color: black;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid var(--primary-color);
    margin-top: 25px;
    float: left;
    animation: fadeIn 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: var(--primary-color);
      color: white;
    }
  }

  .logo-spin {
    margin-top: 1px;
  }
}

@media screen and (max-width: 1200px) {
  .tags,
  .home-page h1::before,
  .home-page h1::after {
    display: none;
  }

  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }

    h1 {
      font-size: 44px;
    }

    .img-badges {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: center;
    }

    .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 125px;
      background-color: var(--primary-color);
      color: white;
      text-align: center;

      &:hover {
        background: var(--background-color);
        color: black;
      }
    }

  }
}
