@import '../../App.scss';

.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 80%;
      float: left;
      clear: none;
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: var(--form-color);
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: var(--form-color);
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: var(--primary-color);
    font-size: 16px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid var(--primary-color);
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
    background-color: var(--form-color);
    transition: 0.2s;
    }
  }
}

.info-map {
  position: absolute;
  background-color: black;
  top: 80px;
  right: 30%;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: var(--card-text);
  font-family: Helvetica;
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;
}

.map-wrap {
  background: lightgray;
  float: right;
  width: 53%;
  height: 100%;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

@media screen and (max-width: 1200px) {
  .map-wrap {
    width: 100%;
    height: 400px;
    margin: 0;
  }

  .info-map {
    display: none;
  }
}
